import { Link, navigate } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import styles from "@/styles/header.module.scss"
import languageStyles from '@/styles/change-language.module.scss'
import arrowStyles from '@/styles/arrow.module.scss'

import logo from "@/images/logo.png"
import logoSml from "@/images/logo-sml.png"

const changeLanguage = (language, location) => {
    return location.pathname.replace(/^\/[a-z]{2}/, `/${language}`)
}

const Header = ({ menuTrigger, triggerModal, toggleLangOpen, langChoiceOpen, location, langKey }) => {
    const [fillColor, setFillColor] = useState("#B783B9")

    const setCookie = (langKey = 'es') => {
        const now = new Date()
        let time = now.getTime()
        time += 60 * 60 * 1000
        now.setTime(time)
        document.cookie = `langKey=${langKey}; expires=${now.toUTCString()}; path=/`
    }

    const goNavigate = () => {
        setCookie()
        navigate(changeLanguage('es', location))
    }

    const handleToggleLangOpen = (e) => {
        e.stopPropagation()
        toggleLangOpen(e)
    }

    return (
        <header className={styles.main}>
            <Link to={`/${langKey}/`} className={styles.logo}>
                <img src={logo} alt="" />
                <img src={logoSml} alt="" />
            </Link>

            <div className={styles.right}>
                <div
                    className={styles.burger}
                    onClick={() => {
                        menuTrigger()
                    }}
                >
                    <div
                        className={styles.burger__top}
                        style={{ "--bg-color": fillColor }}
                    ></div>
                    <div
                        className={styles.burger__bottom}
                        style={{ "--bg-color": fillColor }}
                    ></div>
                </div>
                <div
                    className={styles.link}
                >
                    <div
                        className={[languageStyles.submit, langChoiceOpen ? languageStyles.active : ''].join(' ')}
                        type="submit"
                    >
                        <div 
                            className={languageStyles.text}    
                            onClick={handleToggleLangOpen}
                        >
                            <div>English <span className={[arrowStyles.arrow, langChoiceOpen ? arrowStyles.up : arrowStyles.down].join(' ')}></span></div>
                            
                            <div role="link" className={[languageStyles.otherLang, langChoiceOpen ? languageStyles.active : languageStyles.inactive].join(" ")} onClick={menuTrigger, goNavigate}>Español</div>
                        </div>
                    </div>
                </div>
                <div
                    className={styles.what}
                    style={{
                        "--what-color": fillColor,
                    }}
                    onClick={() => {
                        triggerModal()
                    }}
                >
                    "What Exactly is a Free Market?!"
                </div>
                
            </div>
        </header>
    )
}

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: ``,
}

export default Header
