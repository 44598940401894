import React from "react"
import SizingContext from "../contexts/SizingContext"

const Sizing = props => {
    const { breaks, children, styles } = props

    const genWrapper = ww => {
        let newStyles = Object.assign({}, styles)

        const part = ww / 24

        breaks.forEach(function (point) {
            if ((typeof point.min !== "undefined") & (ww >= point.min)) {
                for (let style in point.val) {
                    if (isNaN(point.val[style])) {
                        newStyles[style] = point.val[style]
                        continue
                    }

                    const val = part * point.val[style]
                    newStyles[style] = val + "px"
                }
            }
        })

        return <div style={newStyles}>{children}</div>
    }

    return (
        <SizingContext.Consumer>{ww => genWrapper(ww)}</SizingContext.Consumer>
    )
}

export default Sizing
