import React from "react"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const Image = ({ src, style = {} }) => {
    const data = useStaticQuery(graphql`
        query {
            wavyLine: file(
                relativePath: { eq: "boredom/motif/wavy-line.png" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 500) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            cluster: file(relativePath: { eq: "boredom/motif/cluster.png" }) {
                childImageSharp {
                    fluid(maxWidth: 500) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            boredom_blockbuster: file(
                relativePath: { eq: "boredom/blockbuster.png" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 2560) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            boredom_streamer: file(
                relativePath: { eq: "boredom/streamer.png" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 2560) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            boredom_hiker: file(relativePath: { eq: "boredom/hiker.png" }) {
                childImageSharp {
                    fluid(maxWidth: 2560) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            climate_flood: file(relativePath: { eq: "climate/flood.png" }) {
                childImageSharp {
                    fluid(maxWidth: 2560) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            climate_forest: file(relativePath: { eq: "climate/forest.png" }) {
                childImageSharp {
                    fluid(maxWidth: 2560) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            climate_loggers: file(relativePath: { eq: "climate/loggers.png" }) {
                childImageSharp {
                    fluid(maxWidth: 2560) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            climate_tech: file(relativePath: { eq: "climate/tech.png" }) {
                childImageSharp {
                    fluid(maxWidth: 2560) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            modal: file(relativePath: { eq: "modal.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 1200) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            rents_capsule: file(relativePath: { eq: "rents/capsule.png" }) {
                childImageSharp {
                    fluid(maxWidth: 2560) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            rents_demo: file(relativePath: { eq: "rents/demo.png" }) {
                childImageSharp {
                    fluid(maxWidth: 2560) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            rents_tents: file(relativePath: { eq: "rents/tents.png" }) {
                childImageSharp {
                    fluid(maxWidth: 2560) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            disease_illu1: file(relativePath: { eq: "disease/illu1.png" }) {
                childImageSharp {
                    fluid(maxWidth: 2560) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            disease_illu2: file(relativePath: { eq: "disease/illu2.png" }) {
                childImageSharp {
                    fluid(maxWidth: 2560) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            disease_lab: file(relativePath: { eq: "disease/lab.png" }) {
                childImageSharp {
                    fluid(maxWidth: 2560) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            disease_advance: file(relativePath: { eq: "disease/advance.png" }) {
                childImageSharp {
                    fluid(maxWidth: 2560) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            hunger_tractor: file(relativePath: { eq: "hunger/tractor.png" }) {
                childImageSharp {
                    fluid(maxWidth: 2560) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            hunger_isle: file(relativePath: { eq: "hunger/isle.png" }) {
                childImageSharp {
                    fluid(maxWidth: 2560) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            hunger_pizza: file(relativePath: { eq: "hunger/pizza.png" }) {
                childImageSharp {
                    fluid(maxWidth: 2560) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            racism_cornucopia: file(
                relativePath: { eq: "racism/cornucopia.png" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 2560) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            racism_sowell: file(relativePath: { eq: "racism/sowell.png" }) {
                childImageSharp {
                    fluid(maxWidth: 2560) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            racism_sowellquote: file(
                relativePath: { eq: "racism/sowell-quote.png" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 2560) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            racism_sowellquote_spanish: file(
                relativePath: { eq: "racism/sowell-quote_spanish.png" }
            ) {
                childImageSharp {
                    fluid(maxWidth: 2560) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }

            racism_bbking: file(relativePath: { eq: "racism/bbking.png" }) {
                childImageSharp {
                    fluid(maxWidth: 2560) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            racism_happy: file(relativePath: { eq: "racism/happy.png" }) {
                childImageSharp {
                    fluid(maxWidth: 2560) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `)

    return <Img style={style} fluid={data[src].childImageSharp.fluid} />
}

export default Image
