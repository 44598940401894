import React from "react"
import Sizing from "./Sizing"
import Spacing from "./Spacing"
import styles from "../../styles/modal.module.scss"
import Close from "./Close"
import Image from "./Image"
import Share from "./Share"

import modal1 from "../../images/modal_1.png"
import modal2 from "../../images/modal_2.png"
import modal3 from "../../images/modal_3.png"

const Modal = ({ triggerClose, modalOpen }) => {
    return (
        <>
            <div
                onClick={triggerClose}
                className={`${styles.main} ${
                    modalOpen ? styles.open : styles.close
                }`}
            ></div>

            <div
                className={`${styles.modal} ${
                    modalOpen ? styles.open : styles.close
                }`}
            >
                <Sizing
                    styles={{ float: "left", boxSizing: "border-box" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                width: 18,
                                padding: 2,
                            },
                        },
                        {
                            min: 860,
                            val: {
                                width: 17,
                                padding: 2,
                            },
                        },

                        {
                            min: 1000,
                            val: {
                                width: 16,
                                padding: 2,
                            },
                        },

                        {
                            min: 1240,
                            val: {
                                width: 15,
                                padding: 2,
                            },
                        },

                        {
                            min: 1680,
                            val: {
                                width: 14,
                                padding: 2,
                            },
                        },

                        {
                            min: 2400,
                            val: {
                                width: 13,
                                padding: 2,
                            },
                        },
                    ]}
                >
                    <h2 className={"heading heading--navy"}>
                        What Exactly is a Free Market?
                    </h2>
                    <Spacing space={"2%"} />
                    <p className={"copy"}>
                        What do we mean by <b>“free markets”</b> or even{" "}
                        <b>“capitalism”</b> for that matter? It’s probably not
                        what you think. For many people, these terms conjure
                        images of Wall Street bankers and multinational
                        corporations. Perhaps they also bring to mind small
                        businesses and scrappy startups. All of those are part
                        of free market capitalism, but they’re only one piece of
                        the puzzle.
                    </p>
                    <p className={"copy"}>
                        To understand the whole picture, you need to start with
                        a single individual.
                    </p>
                    <p className={"copy"}>
                        Start with yourself. You have hopes and dreams, talents
                        and passions. You want to build a life that brings you
                        and the ones you love happiness and excitement even as
                        you achieve independence and stability. Ultimately, you
                        want to build a life with meaning, filled with purpose.
                        Everyone of us wants that for ourselves. And for
                        each one of us, that life looks as unique and diverse as
                        we all are. For all the things we share as a community
                        and a society, there is no getting around the fact that
                        each of us values different things at different times in
                        different ways. One person’s trash is another person’s
                        treasure. No one can know you as well as you know
                        yourself. The same is true for every one of us.
                    </p>
                    <p className={"copy"}>
                        So here you are, a unique individual, out in the world
                        trying to figure out life. Doing it the “free market”
                        way means following three pretty simple rules.
                    </p>
                    <div className={styles.rules}>
                        <div className={styles.rule}>
                            <Sizing
                                styles={{ flexGrow: 0, flexShrink: 0 }}
                                breaks={[
                                    {
                                        min: 0,
                                        val: {
                                            flexBasis: 2,
                                        },
                                    },
                                ]}
                            >
                                <div className={styles.num}>
                                    <img src={modal1} alt="" />
                                </div>
                            </Sizing>

                            <div className={styles.ruleText + " copy"}>
                                The first and most important rule:{" "}
                                <b>
                                    don’t hurt other people or steal their
                                    stuff.
                                </b>{" "}
                                Human history is filled with plundering tyrants
                                hurting other people and stealing their stuff.
                                That’s not what free markets are about. In a
                                free market, every transaction should be
                                voluntary and based on mutual consent. There’s
                                nothing “free market” about slavery or
                                subjugation, which is why some of the original
                                promoters of free markets, people like Adam
                                Smith and John Stuart Mill, were also passionate
                                abolitionists. Free markets aren’t about money,
                                they’re about replacing violence with voluntary
                                exchange.
                            </div>
                        </div>

                        <div className={styles.rule}>
                            <Sizing
                                styles={{ flexGrow: 0, flexShrink: 0 }}
                                breaks={[
                                    {
                                        min: 0,
                                        val: {
                                            flexBasis: 2,
                                        },
                                    },
                                ]}
                            >
                                <div className={styles.num}>
                                    <img src={modal2} alt="" />
                                </div>
                            </Sizing>

                            <div className={styles.ruleText + " copy"}>
                                The second rule is simple:{" "}
                                <b>keep your word.</b> Free markets are about
                                people reaching agreements with each other to
                                work, trade or invest with mutual consent. It’s
                                a system based on trust, from a handshake
                                agreement to a formal contract. At the heart of
                                free markets is human relationships and respect
                                for each other. Private property is built on the
                                promises you make in peacefully creating it or
                                acquiring it from someone else. Corporations,
                                nonprofits and other organizations in a free
                                market are nothing more than the collection of
                                agreements between all of the people involved in
                                the enterprise. They can’t exist without the
                                consent of the people that create them and
                                contribute to them.
                            </div>
                        </div>

                        <div className={styles.rule}>
                            <Sizing
                                styles={{ flexGrow: 0, flexShrink: 0 }}
                                breaks={[
                                    {
                                        min: 0,
                                        val: {
                                            flexBasis: 2,
                                        },
                                    },
                                ]}
                            >
                                <div className={styles.num}>
                                    <img src={modal3} alt="" />
                                </div>
                            </Sizing>

                            <div className={styles.ruleText + " copy"}>
                                The final rule, assuming you’ve followed the
                                first two: <b>no permission required.</b> Put
                                another way, you’re free to try, you’re free to
                                buy, and you’re free to leave. This is the
                                essential “freedom” in free markets. If you’ve
                                got an idea for a new company, a new medicine, a
                                new school or new way to deliver the mail,
                                nobody has the right to stop you. You don’t need
                                to seek permission from the king, the president
                                or post office. This is also the key to holding
                                other people accountable. If your work isn’t up
                                to snuff, your workplace is oppressive, your
                                prices are too high or your product isn’t good,
                                your competitors and your customers aren’t going
                                to let you get away with it for very long.
                            </div>
                        </div>
                    </div>
                    <p className={"copy"}>
                        Those are the rules of the free market: so long as you
                        don’t hurt other people or steal their stuff, and so
                        long as you keep your word, you’re free to do whatever
                        you want. You’re free to work for whoever you want.
                        You’re free to love and marry whoever you want. You’re
                        free to worship whoever or whatever you want. You’re
                        free to trade with whoever you want.
                    </p>
                    <p className={"copy"}>
                        And with this freedom, you’re empowered to take on any
                        problem you want to. Free markets destroy the problems
                        in our society because they empower free people to
                        destroy those problems and offer new solutions. Free
                        markets empower everyone, but especially underdogs,
                        upstarts and immigrants to take on the status quo
                        precisely because they don’t need to seek permission
                        first. Free markets don’t respect traditions unless
                        those traditions are maintained peacefully. Free markets
                        are the most radical, revolutionary force for change
                        humanity has ever discovered. And the reason is simple.
                        Free markets are nothing more and nothing less than each
                        of us and all of us having the right to try our best at
                        improving everything for each other.
                    </p>

                    <Sizing
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    width: "100%",
                                    maxWidth: "320px",
                                    height: "50px",
                                    marginTop: 2,
                                    paddingBottom: 1,
                                    position: "relative",
                                    display: "block",
                                },
                            },
                        ]}
                    >
                        <Share type="modal"></Share>
                    </Sizing>

                    <div className={styles.image}>
                        <Image src={"modal"}></Image>
                    </div>
                </Sizing>
            </div>

            <div
                // className={styles.closeBtn}
                className={`${styles.closeBtn} ${
                    modalOpen ? styles.open : styles.close
                }`}
            >
                <Close onClick={triggerClose}></Close>
            </div>
        </>
    )
}

export default Modal

// export default modal
