import React, { useState, useEffect } from "react"
import { hot } from "react-hot-loader/root"
import { TransitionGroup, Transition } from "react-transition-group"
import SizingContext from "@/components/contexts/SizingContext"
import Menu from "@/components/general/Menu"
import Header from "@/components/general/Header"
import Modal from "@/components/general/Modal"

import styles from "@/styles/layout.module.scss"

import "@/styles/global.scss"
import "@/styles/bu.scss"

// import browserUpdate from "browser-update"

let browserUpdate

if (typeof window !== "undefined") browserUpdate = require("browser-update")

const timeout = 300
const getTransitionStyles = {
    entering: {
        position: "absolute",
        opacity: 0,
    },
    entered: {
        transition: `opacity ${timeout}ms ease-in-out`,
        opacity: 1,
    },
    exiting: {
        transition: `all ${timeout}ms ease-in-out`,
        opacity: 0,
    },
}

const Layout = ({ children, location, pageContext }) => {
    const [ww, setWw] = useState(0)
    const [y, setY] = useState(0)
    const [lock, setLock] = useState(false)
    const [menuOpen, setMenuOpen] = useState(false)
    const [modalOpen, setModalOpen] = useState(false)
    const [langChoiceOpen, setLangChoiceOpen] = useState(false)

    const lockPage = isLock => {
        if (isLock) {
            setY(window.pageYOffset)
        }

        setLock(isLock)
    }

    const triggerMenu = open => {
        setMenuOpen(open)
        // lockPage(open)
    }

    const triggerModal = open => {
        setModalOpen(open)

        if (open) {
            window.dataLayer.push({
                event: "VirtualPageview",
                virtualPageURL: "/what-exactly-is-a-free-market-!?",
                virtualPageTitle: "What Exactly is a Free Market!?",
            })
        }

        // lockPage(open)
    }

    const toggleLangOpen = (e) => {
        if (e.target.getAttribute('role') !== 'link') {
            setLangChoiceOpen(!langChoiceOpen)
        }
    }

    const langChoiceClose = () => {
        setLangChoiceOpen(false)
    }

    useEffect(() => {
        if (typeof window === "undefined") return

        const resize = () => {
            setWw(document.body.clientWidth)
        }

        resize()

        window.addEventListener("load", resize)
        window.addEventListener("resize", resize)

        return () => {
            window.removeEventListener("resize", resize)
        }
    }, [ww])

    useEffect(() => {
        const opts = {
            required: {
                e: -10,
                f: -10,
                o: -3,
                s: -3,
                c: -10,
            },
            insecure: true,
            // test: true,
            style: "top",
            text: {
                msg: "Your web browser ({brow_name}) is out of date.",
                msgmore:
                    "This site may appear broken. Please update your browser for a better experience.",
            },
        }
        if (typeof window !== "undefined") browserUpdate(opts)
    }, [])

    useEffect(() => {
        if (!lock) window.scroll(0, y)
    }, [lock, y])

    return (
        <div 
            onClick={langChoiceClose}>
            <SizingContext.Provider value={ww}>
                <Menu
                    open={menuOpen}
                    menuTrigger={() => {
                        triggerMenu(false)
                    }}
                    triggerModal={() => {
                        triggerModal(true)
                    }}
                    pageContext={pageContext}
                />
                <Modal
                    modalOpen={modalOpen}
                    triggerClose={() => {
                        triggerModal(false)
                    }}
                />
                <Header
                    triggerModal={() => {
                        triggerModal(true)
                    }}
                    menuTrigger={() => {
                        triggerMenu(true)
                    }}
                    toggleLangOpen={(e) => {
                        toggleLangOpen(e)
                    }}
                    langChoiceOpen={langChoiceOpen}
                    location={location}
                    langKey={pageContext.langKey}
                />
                <div
                    className={`${styles.overflow} ${lock ? styles.lock : ""}`}
                >
                    <div
                        className={`${styles.traslate} ${
                            lock ? styles.lock : ""
                        }`}
                        style={{
                            transform: `${
                                lock ? `translateY(-${y}px)` : "none"
                            }`,
                        }}
                    >
                        <TransitionGroup>
                            <Transition
                                key={location.pathname}
                                timeout={{ enter: timeout, exit: timeout }}
                            >
                                {status => {
                                    return (
                                        <div
                                            className={status}
                                            style={{
                                                ...getTransitionStyles[status],
                                            }}
                                        >
                                            {children}
                                        </div>
                                    )
                                }}
                            </Transition>
                        </TransitionGroup>{" "}
                    </div>
                </div>
            </SizingContext.Provider>
        </div>
    )
}
export default process.env.NODE_ENV === "development" ? hot(Layout) : Layout
