import React, { useEffect, useRef } from "react"
import { af as AF } from "@gladeye/af"

import styles from "../../styles/cursor.module.scss"

const Cursor = ({ active }) => {
    const ref = useRef()

    useEffect(() => {
        const af = AF()
        const write = () => {}

        const mousemove = e => {
            const { clientX: x, clientY: y } = e

            ref.current.style.transform = `translate3d(${x}px, ${y}px, 1px)`
        }

        window.addEventListener("mousemove", mousemove)

        af.addWrite(write)

        return () => {
            window.removeEventListener("mousemove", mousemove)
            af.removeWrite(write)
        }
    })
    return (
        <div
            className={`${styles.cursor} ${active ? styles.active : ""}`}
            ref={ref}
        ></div>
    )
}

export default Cursor
