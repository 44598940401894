import React from "react"

const Spacing = ({ space }) => {
    return (
        <>
            <div
                style={{
                    width: "100%",
                    paddingBottom: space,
                    display: "block",
                    clear: "both",
                }}
            ></div>
            {/* <div style={{ clear: "both", display: "table" }}></div> */}
        </>
    )
}

export default Spacing
