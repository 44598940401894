import React, { useRef, useEffect, useState } from "react"
import styles from "@/styles/menu.module.scss"
import formStyles from "@/styles/menu-form.module.scss"
import Sizing from "./Sizing"
import MenuList from "./MenuList"
import Spacing from "./Spacing"
import Close from "./Close"
import Cursor from "./Cursor"
import Form from "./Form.es"
import { Link } from "gatsby"

const Menu = ({ open, menuTrigger, triggerModal, pageContext }) => {
    const [success, setSuccess] = useState(false)
    const triggerSuccess = () => {}

    const formOrSuccess = () => {
        if (!success) {
            return (
                <Form
                    styles={formStyles}
                    triggerSuccess={() => {
                        setSuccess(true)
                    }}
                ></Form>
            )
        } else {
            return (
                <div className={styles.success}>
                    <span className={styles.heading + " h4"}>
                        Gracias por suscribirte a Free Markets Destroy.
                    </span>
                    <span className={styles.copy}>
                        Pronto tendrá noticias nuestras.
                    </span>
                </div>
            )
        }
    }

    return (
        <>
            <div
                className={
                    styles.main + " " + (open ? styles.show : "") + " hand"
                }
            >
                <div className={styles.menuInner}>
                    <Sizing
                        styles={{
                            float: "left",
                        }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    display: "none",
                                },
                            },
                            {
                                min: 1024,
                                val: {
                                    display: "block",
                                    width: 21,
                                    marginLeft: 2,
                                    marginTop: 1,
                                },
                            },
                            {
                                min: 1200,
                                val: {
                                    width: 21,
                                    marginLeft: 3,
                                    marginTop: 1,
                                },
                            },
                        ]}
                    >
                        <span
                            className={styles.intro + " copy--white copy--lead"}
                        >
                            FREE MARKETS DESTROY
                        </span>
                    </Sizing>
                    <Sizing
                        styles={{
                            float: "left",
                        }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    width: "90%",
                                    maxWidth: "700px",

                                    marginLeft: 2,
                                    marginTop: 1,
                                },
                            },
                            {
                                min: 1024,
                                val: {
                                    width: 12,
                                    marginLeft: 2,
                                    marginTop: 1,
                                },
                            },
                            {
                                min: 1200,
                                val: {
                                    width: 11,
                                    marginLeft: 3,
                                    marginTop: 1,
                                },
                            },
                            {
                                min: 1600,
                                val: {
                                    width: 9,
                                    marginLeft: 3,
                                    marginTop: 1,
                                },
                            },
                        ]}
                    >
                        <div className={styles.wrap}>
                            <MenuList
                                setSelection={() => {}}
                                triggerClose={menuTrigger}
                                langKey={pageContext.langKey}
                            />
                        </div>
                    </Sizing>

                    <Sizing
                        styles={{
                            float: "left",
                        }}
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    width: 20,
                                    marginLeft: 2,
                                    marginTop: 1,
                                    marginBottom: 2,
                                },
                            },
                            {
                                min: 1024,
                                val: {
                                    width: 7,
                                    marginLeft: 1,
                                    marginTop: 1,
                                    marginBottom: 0,
                                },
                            },
                            {
                                min: 1600,
                                val: {
                                    width: 8,
                                    marginLeft: 1,
                                    marginTop: 1,
                                    marginBottom: 0,
                                },
                            },
                        ]}
                    >
                        <div className={styles.mobFlip}>
                            <div className={styles.mobRow}>
                                <Link
                                    to="/es/about"
                                    onClick={menuTrigger}
                                    className={styles.link}
                                >
                                    Acerca
                                </Link>

                                <div
                                    className={
                                        styles.link + " " + styles.modalLink
                                    }
                                    onClick={triggerModal}
                                >
                                    ¿Qué es exactamente un mercado libre?
                                </div>
                            </div>

                            <Spacing space="5%"></Spacing>
                            <div className={styles.mobRow}>
                                <Sizing
                                    breaks={[
                                        {
                                            min: 0,
                                            val: {
                                                flexGrow: 0,
                                                flexShrink: 0,
                                                flexBasis: 10,
                                                marginLeft: 2,
                                            },
                                        },
                                        {
                                            min: 1024,
                                            val: {
                                                width: "100%",
                                                marginLeft: 0,
                                            },
                                        },
                                    ]}
                                >
                                    <a
                                        target="_blank"
                                        href="https://www.washingtonpolicy.org/marketsimproveeverything"
                                        className={
                                            styles.link + " " + styles.arrow
                                        }
                                    >
                                        Los mercados lo mejoran todo{" "}
                                        <span>Blog</span>
                                    </a>
                                </Sizing>

                                <Sizing
                                    breaks={[
                                        {
                                            min: 0,
                                            val: {
                                                flexGrow: 0,
                                                flexShrink: 0,
                                                flexBasis: 10,
                                            },
                                        },
                                        {
                                            min: 1024,
                                            val: {
                                                width: "100%",
                                                marginLeft: 0,
                                            },
                                        },
                                    ]}
                                >
                                    <a
                                        target="_blank"
                                        href="https://store.freemarketsdestroy.com"
                                        className={
                                            styles.link + " " + styles.arrow
                                        }
                                    >
                                        Mercancía de Free Markets{" "}
                                        <span>Destroy</span>
                                    </a>
                                </Sizing>
                            </div>

                            <div className={styles.formContainer}>
                                <span className={"copy copy--white copy--bold"}>
                                    {success ? "¡Éxito!" : "Mantenerse al día"}
                                </span>

                                {formOrSuccess()}
                            </div>

                        </div>
                    </Sizing>
                </div>

                <div className={styles.close + " hand"}>
                    <Close onClick={menuTrigger}></Close>
                </div>
            </div>

            <Cursor active={open}></Cursor>
        </>
    )
}

export default Menu
