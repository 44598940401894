import React, { useState, useEffect, useRef } from "react"
import pattern from "@/images/footer-copy-fill.png"
import styles from "@/styles/menu-list-item.module.scss"

import climateA from "@/images/menu/active/climate.png"
import climateI from "@/images/menu/idle/climate.png"
import climateM from "@/images/mobile-menu/climate.png"

import rentsA from "@/images/menu/active/rents.png"
import rentsI from "@/images/menu/idle/rents.png"
import rentsM from "@/images/mobile-menu/rents.png"

import boredomA from "@/images/menu/active/boredom.png"
import boredomI from "@/images/menu/idle/boredom.png"
import boredomM from "@/images/mobile-menu/boredom.png"

import hungerA from "@/images/menu/active/hunger.png"
import hungerI from "@/images/menu/idle/hunger.png"
import hungerM from "@/images/mobile-menu/hunger.png"

import diseaseA from "@/images/menu/active/disease.png"
import diseaseI from "@/images/menu/idle/disease.png"
import diseaseM from "@/images/mobile-menu/disease.png"

import racismA from "@/images/menu/active/racism.png"
import racismI from "@/images/menu/idle/racism.png"
import racismM from "@/images/mobile-menu/racism.png"

import climateA_Spanish from "@/images/menu/active/climate_spanish.png"
import climateI_Spanish from "@/images/menu/idle/climate_spanish.png"
import climateM_Spanish from "@/images/mobile-menu/climate_spanish.png"

import rentsA_Spanish from "@/images/menu/active/rents_spanish.png"
import rentsI_Spanish from "@/images/menu/idle/rents_spanish.png"
import rentsM_Spanish from "@/images/mobile-menu/rents_spanish.png"

import boredomA_Spanish from "@/images/menu/active/boredom_spanish.png"
import boredomI_Spanish from "@/images/menu/idle/boredom_spanish.png"
import boredomM_Spanish from "@/images/mobile-menu/boredom_spanish.png"

import hungerA_Spanish from "@/images/menu/active/hunger_spanish.png"
import hungerI_Spanish from "@/images/menu/idle/hunger_spanish.png"
import hungerM_Spanish from "@/images/mobile-menu/hunger_spanish.png"

import diseaseA_Spanish from "@/images/menu/active/disease_spanish.png"
import diseaseI_Spanish from "@/images/menu/idle/disease_spanish.png"
import diseaseM_Spanish from "@/images/mobile-menu/disease_spanish.png"

import racismA_Spanish from "@/images/menu/active/racism_spanish.png"
import racismI_Spanish from "@/images/menu/idle/racism_spanish.png"
import racismM_Spanish from "@/images/mobile-menu/racism_spanish.png"

const images = {
    en: {
        boredom: [boredomA, boredomI, boredomM],
        climate: [climateA, climateI, climateM],
        rents: [rentsA, rentsI, rentsM],
        hunger: [hungerA, hungerI, hungerM],
        disease: [diseaseA, diseaseI, diseaseM],
        racism: [racismA, racismI, racismM],
    },
    es: {
        boredom: [boredomA_Spanish, boredomI_Spanish, boredomM_Spanish],
        climate: [climateA_Spanish, climateI_Spanish, climateM_Spanish],
        rents: [rentsA_Spanish, rentsI_Spanish, rentsM_Spanish],
        hunger: [hungerA_Spanish, hungerI_Spanish, hungerM_Spanish],
        disease: [diseaseA_Spanish, diseaseI_Spanish, diseaseM_Spanish],
        racism: [racismA_Spanish, racismI_Spanish, racismM_Spanish],
    }
}

const MenuItem = ({ section, setSelection, langKey = 'en' }) => {
    const [active, setActive] = useState(false)
    const ref = useRef()

    const { colors, text, slug } = section
    const rand = Math.floor(Math.random() * 10000)

    // console.log(section)
    const idleImage = images[langKey][slug][1]
    const activeImage = images[langKey][slug][0]
    const mobileImage = images[langKey][slug][2]

    useEffect(() => {
        function enter() {
            setActive(true)
            setSelection(slug, true)
        }

        function leave() {
            setActive(false)
            setSelection(slug, false)
        }

        ref.current.addEventListener("mouseenter", enter)
        ref.current.addEventListener("mouseleave", leave)

        return () => {
            ref.current.removeEventListener("mouseenter", enter)
            ref.current.removeEventListener("mouseleave", leave)
        }
    }, [active])

    return (
        <div
            ref={ref}
            className={
                styles.main + " " + (slug == "climate" ? styles.double : "")
            }
        >
            <img src={idleImage} alt="" />
            <img
                src={activeImage}
                className={active ? styles.active : ""}
                alt=""
            />
            <img src={mobileImage} alt="" />
        </div>
    )
}

export default MenuItem
