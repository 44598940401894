import React from "react"
import styles from "../../styles/close.module.scss"

const Close = ({ onClick }) => {
    return (
        <div onClick={onClick} className={styles.main}>
            <div className={styles.bottom}></div>
            <div className={styles.top}></div>
        </div>
    )
}

export default Close
