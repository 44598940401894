import React from "react"
import Sizing from "./Sizing"
import Spacing from "./Spacing"
import styles from "../../styles/modal.module.scss"
import Close from "./Close"
import Image from "./Image"
import Share from "./Share.es"

import modal1 from "../../images/modal_1.png"
import modal2 from "../../images/modal_2.png"
import modal3 from "../../images/modal_3.png"

const Modal = ({ triggerClose, modalOpen }) => {
    return (
        <>
            <div
                onClick={triggerClose}
                className={`${styles.main} ${
                    modalOpen ? styles.open : styles.close
                }`}
            ></div>

            <div
                className={`${styles.modal} ${
                    modalOpen ? styles.open : styles.close
                }`}
            >
                <Sizing
                    styles={{ float: "left", boxSizing: "border-box" }}
                    breaks={[
                        {
                            min: 0,
                            val: {
                                width: 18,
                                padding: 2,
                            },
                        },
                        {
                            min: 860,
                            val: {
                                width: 17,
                                padding: 2,
                            },
                        },

                        {
                            min: 1000,
                            val: {
                                width: 16,
                                padding: 2,
                            },
                        },

                        {
                            min: 1240,
                            val: {
                                width: 15,
                                padding: 2,
                            },
                        },

                        {
                            min: 1680,
                            val: {
                                width: 14,
                                padding: 2,
                            },
                        },

                        {
                            min: 2400,
                            val: {
                                width: 13,
                                padding: 2,
                            },
                        },
                    ]}
                >
                    <h2 className={"heading heading--navy"}>
                        ¿QUÉ ES EXACTAMENTE UN MERCADO LIBRE?
                    </h2>
                    <Spacing space={"2%"} />
                    <p className={"copy"}>
                        ¿Qué queremos decir con <b>“mercados libres”</b> o incluso {" "}
                        <b>“capitalismo”</b> para el caso? Probablemente no sea lo que piensas. Para muchas personas, estos términos evocan imágenes de los banqueros de Wall Street y las corporaciones multinacionales. Quizás también traen a la mente pequeñas empresas y nuevas empresas. Todos ellos son parte del capitalismo de libre mercado, pero son solo una pieza del rompecabezas.
                    </p>
                    <p className={"copy"}>
                        Para comprender el panorama completo, debe comenzar con una sola persona.
                    </p>
                    <p className={"copy"}>
                        Empiece por usted mismo. Tienes esperanzas y sueños, talentos y pasiones. Quieres construir una vida que te traiga felicidad y emoción a ti y a tus seres queridos, incluso cuando alcanzas la independencia y la estabilidad. En última instancia, desea construir una vida con significado, llena de propósito. Todos queremos eso para nosotros. Y para cada uno de nosotros, esa vida parece tan única y diversa como todos nosotros. A pesar de todas las cosas que compartimos como comunidad y sociedad, no hay forma de eludir el hecho de que cada uno de nosotros valora cosas diferentes en diferentes momentos de diferentes maneras. La basura de una persona es el tesoro de otra. Nadie puede conocerte tan bien como tú mismo. Lo mismo es cierto para todos nosotros.
                    </p>
                    <p className={"copy"}>
                        Así que aquí estás, un individuo único, en el mundo tratando de descubrir la vida. Hacerlo a la manera del "mercado libre" significa seguir tres reglas bastante simples.
                    </p>
                    <div className={styles.rules}>
                        <div className={styles.rule}>
                            <Sizing
                                styles={{ flexGrow: 0, flexShrink: 0 }}
                                breaks={[
                                    {
                                        min: 0,
                                        val: {
                                            flexBasis: 2,
                                        },
                                    },
                                ]}
                            >
                                <div className={styles.num}>
                                    <img src={modal1} alt="" />
                                </div>
                            </Sizing>

                            <div className={styles.ruleText + " copy"}>
                                La primera y más importante regla:{" "}
                                <b>
                                    no lastimes a otras personas ni robes sus cosas.
                                </b>{" "}
                                La historia humana está llena de tiranos saqueadores que lastiman a otras personas y roban sus cosas. De eso no se tratan los mercados libres. En un mercado libre, toda transacción debe ser voluntaria y basada en el consentimiento mutuo. No hay nada de "libre mercado" en la esclavitud o la subyugación, razón por la cual algunos de los promotores originales del libre mercado, personas como Adam Smith y John Stuart Mill, también fueron apasionados abolicionistas. Los mercados libres no se tratan de dinero, se trata de reemplazar la violencia con el intercambio voluntario.
                            </div>
                        </div>

                        <div className={styles.rule}>
                            <Sizing
                                styles={{ flexGrow: 0, flexShrink: 0 }}
                                breaks={[
                                    {
                                        min: 0,
                                        val: {
                                            flexBasis: 2,
                                        },
                                    },
                                ]}
                            >
                                <div className={styles.num}>
                                    <img src={modal2} alt="" />
                                </div>
                            </Sizing>

                            <div className={styles.ruleText + " copy"}>
                                La segunda regla es simple:{" "}
                                <b>cumple tu palabra.</b> Los mercados libres consisten en que las personas lleguen a acuerdos entre sí para trabajar, comerciar o invertir con consentimiento mutuo. Es un sistema basado en la confianza, desde un acuerdo de apretón de manos hasta un contrato formal. En el corazón del libre mercado están las relaciones humanas y el respeto mutuo. La propiedad privada se basa en las promesas que usted hace al crearla pacíficamente o adquirirla de otra persona. Las corporaciones, organizaciones sin fines de lucro y otras organizaciones en un mercado libre no son más que el conjunto de acuerdos entre todas las personas involucradas en la empresa. No pueden existir sin el consentimiento de las personas que los crean y contribuyen a ellos.
                            </div>
                        </div>

                        <div className={styles.rule}>
                            <Sizing
                                styles={{ flexGrow: 0, flexShrink: 0 }}
                                breaks={[
                                    {
                                        min: 0,
                                        val: {
                                            flexBasis: 2,
                                        },
                                    },
                                ]}
                            >
                                <div className={styles.num}>
                                    <img src={modal3} alt="" />
                                </div>
                            </Sizing>

                            <div className={styles.ruleText + " copy"}>
                                La regla final, asumiendo que ha seguido las dos primeras: <b>no se requiere permiso.</b> Dicho de otra manera, eres libre de probar, eres libre de comprar y eres libre de irte. Ésta es la "libertad" esencial en los mercados libres. Si tienes una idea para una nueva empresa, un nuevo medicamento, una nueva escuela o una nueva forma de entregar el correo, nadie tiene derecho a detenerte. No es necesario solicitar el permiso del rey, el presidente o la oficina de correos. Esta es también la clave para hacer que otras personas rindan cuentas. Si su trabajo no está a la altura, su lugar de trabajo es opresivo, sus precios son demasiado altos o su producto no es bueno, sus competidores y sus clientes no van a dejar que se salga con la suya por mucho tiempo.
                            </div>
                        </div>
                    </div>
                    <p className={"copy"}>
                        Esas son las reglas del mercado libre: siempre que no lastimes a otras personas ni les robes sus cosas, y mientras cumplas tu palabra, eres libre de hacer lo que quieras. Eres libre de trabajar para quien quieras. Eres libre de amar y casarte con quien quieras. Eres libre de adorar a quien quieras o lo que quieras. Eres libre de comerciar con quien quieras.
                    </p>
                    <p className={"copy"}>
                        Y con esta libertad, está capacitado para abordar cualquier problema que desee. Los mercados libres destruyen los problemas de nuestra sociedad porque empoderan a las personas libres para destruir esos problemas y ofrecer nuevas soluciones. Los mercados libres empoderan a todos, pero especialmente a los desamparados, advenedizos e inmigrantes para asumir el status quo precisamente porque no necesitan pedir permiso primero. Los mercados libres no respetan las tradiciones a menos que esas tradiciones se mantengan pacíficamente. Los mercados libres son la fuerza revolucionaria más radical para el cambio que la humanidad haya descubierto. Y la razón es simple. Los mercados libres son nada más y nada menos que cada uno de nosotros y todos tenemos el derecho de hacer nuestro mejor esfuerzo para mejorar todo para los demás.
                    </p>

                    <Sizing
                        breaks={[
                            {
                                min: 0,
                                val: {
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                    width: "100%",
                                    maxWidth: "320px",
                                    height: "50px",
                                    marginTop: 2,
                                    paddingBottom: 1,
                                    position: "relative",
                                    display: "block",
                                },
                            },
                        ]}
                    >
                        <Share type="modal"></Share>
                    </Sizing>

                    <div className={styles.image}>
                        <Image src={"modal"}></Image>
                    </div>
                </Sizing>
            </div>

            <div
                // className={styles.closeBtn}
                className={`${styles.closeBtn} ${
                    modalOpen ? styles.open : styles.close
                }`}
            >
                <Close onClick={triggerClose}></Close>
            </div>
        </>
    )
}

export default Modal

// export default modal
