import React, { useState, useEffect, useRef } from "react"
import styles from "../../styles/menu-list.module.scss"
import { Link } from "gatsby"

import MenuItem from "./MenuItem"

const colors = {
    red: "#EC5843",
    pink: "#F2AEBB",
    lilac: "#B783B9",
    orange: "#E99245",
    teal: "#68C5B8",
    green: "#D1DD26",
    blue: "#3699DD",
}

const sections = [
    {
        slug: "racism",
        link: "racism",
        text: "RACISM",
        colors: [colors.lilac, colors.pink],
        g1: [colors.pink, colors.orange],
        g2: [colors.green, colors.teal],
    },
    {
        slug: "disease",
        link: "disease",
        text: "DISEASE",
        colors: [colors.lilac, colors.pink],
        g1: [colors.pink, colors.orange],
        g2: [colors.green, colors.teal],
    },
    {
        slug: "hunger",
        link: "hunger",
        text: "HUNGER",
        colors: [colors.lilac, colors.teal],
        g1: [colors.teal, colors.lilac],
        g2: [colors.orange, colors.pink],
    },
    {
        slug: "rents",
        link: "high-rents",
        text: "HIGH RENTS",
        colors: [colors.red, colors.blue],
        g1: [colors.pink, colors.lilac],
        g2: [colors.red, colors.teal],
    },
    {
        slug: "climate",
        link: "climate-change",
        text: "CLIMATE CHANGE",
        colors: [colors.teal, colors.green],
        g1: [colors.green, colors.teal],
        g2: [colors.orange, colors.blue],
    },
    {
        slug: "boredom",
        link: "boredom",
        text: "BOREDOM",
        colors: [colors.green, colors.lilac],
        g1: [colors.lilac, colors.blue],
        g2: [colors.red, colors.green],
    },
]


const MenuList = ({ setSelection, triggerClose = () => {}, langKey }) => {
    const [options, updateOptions] = useState({
        racism: false,
        disease: false,
        rents: false,
        climate: false,
        boredom: false,
        hunger: false,
    })

    let keys = 0

    function select(slug, active) {
        const obj = Object.assign({}, options)

        obj[slug] = active
        updateOptions(obj)
    }

    useEffect(() => {
        let slug = "default"
        for (let prop in options) {
            if (options[prop]) slug = prop
        }

        setSelection(slug)
    }, [options])

    function genTitle(section) {
        return (
            <Link
                onClick={triggerClose}
                to={`/${langKey}/` + section.link}
                key={"menu-item-" + keys++}
                className={styles.link}
            >
                <MenuItem section={section} setSelection={select} langKey={langKey} />
            </Link>
        )
    }

    return (
        <div className={styles.main}>
            {sections.map(section => {
                return genTitle(section)
            })}
        </div>
    )
}

export default MenuList
