// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-404-en-js": () => import("./../src/pages/en/404.en.js" /* webpackChunkName: "component---src-pages-en-404-en-js" */),
  "component---src-pages-en-about-en-js": () => import("./../src/pages/en/about.en.js" /* webpackChunkName: "component---src-pages-en-about-en-js" */),
  "component---src-pages-en-boredom-en-js": () => import("./../src/pages/en/boredom.en.js" /* webpackChunkName: "component---src-pages-en-boredom-en-js" */),
  "component---src-pages-en-climate-change-en-js": () => import("./../src/pages/en/climate-change.en.js" /* webpackChunkName: "component---src-pages-en-climate-change-en-js" */),
  "component---src-pages-en-disease-en-js": () => import("./../src/pages/en/disease.en.js" /* webpackChunkName: "component---src-pages-en-disease-en-js" */),
  "component---src-pages-en-high-rents-en-js": () => import("./../src/pages/en/high-rents.en.js" /* webpackChunkName: "component---src-pages-en-high-rents-en-js" */),
  "component---src-pages-en-hunger-en-js": () => import("./../src/pages/en/hunger.en.js" /* webpackChunkName: "component---src-pages-en-hunger-en-js" */),
  "component---src-pages-en-index-en-js": () => import("./../src/pages/en/index.en.js" /* webpackChunkName: "component---src-pages-en-index-en-js" */),
  "component---src-pages-en-privacy-policy-en-js": () => import("./../src/pages/en/privacy-policy.en.js" /* webpackChunkName: "component---src-pages-en-privacy-policy-en-js" */),
  "component---src-pages-en-racism-en-js": () => import("./../src/pages/en/racism.en.js" /* webpackChunkName: "component---src-pages-en-racism-en-js" */),
  "component---src-pages-es-404-es-js": () => import("./../src/pages/es/404.es.js" /* webpackChunkName: "component---src-pages-es-404-es-js" */),
  "component---src-pages-es-about-es-js": () => import("./../src/pages/es/about.es.js" /* webpackChunkName: "component---src-pages-es-about-es-js" */),
  "component---src-pages-es-boredom-es-js": () => import("./../src/pages/es/boredom.es.js" /* webpackChunkName: "component---src-pages-es-boredom-es-js" */),
  "component---src-pages-es-climate-change-es-js": () => import("./../src/pages/es/climate-change.es.js" /* webpackChunkName: "component---src-pages-es-climate-change-es-js" */),
  "component---src-pages-es-disease-es-js": () => import("./../src/pages/es/disease.es.js" /* webpackChunkName: "component---src-pages-es-disease-es-js" */),
  "component---src-pages-es-high-rents-es-js": () => import("./../src/pages/es/high-rents.es.js" /* webpackChunkName: "component---src-pages-es-high-rents-es-js" */),
  "component---src-pages-es-hunger-es-js": () => import("./../src/pages/es/hunger.es.js" /* webpackChunkName: "component---src-pages-es-hunger-es-js" */),
  "component---src-pages-es-index-es-js": () => import("./../src/pages/es/index.es.js" /* webpackChunkName: "component---src-pages-es-index-es-js" */),
  "component---src-pages-es-privacy-policy-es-js": () => import("./../src/pages/es/privacy-policy.es.js" /* webpackChunkName: "component---src-pages-es-privacy-policy-es-js" */),
  "component---src-pages-es-racism-es-js": () => import("./../src/pages/es/racism.es.js" /* webpackChunkName: "component---src-pages-es-racism-es-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

