import React, { useRef, useEffect, useState } from "react"
import styles from "../../styles/share.module.scss"

const Share = ({ type = "none" }) => {
    const [location, setLocation] = useState("https://freemarketsdestroy.com")

    const share = () => {
        window.dataLayer.push({ event: "ShareClicked" })
    }

    const shareLink = e => {
        e.preventDefault()
        let url = location

        if (navigator.share) {
            navigator
                .share({
                    title: "Markets Improve Everything.",
                    url: url,
                })
                .then(() => {
                    console.log("Thanks for sharing!")
                })
                .catch(console.error)
        } else {
            // fallback
            const el = document.createElement("textarea")
            el.value = url
            document.body.appendChild(el)
            el.select()
            document.execCommand("copy")
            document.body.removeChild(el)
            alert("Copied to clipboard!")
        }
    }

    useEffect(() => {
        if (typeof window !== "undefined") {
            if (type === "share") setLocation(window.location.origin)
            else setLocation(window.location.href)
        }
    }, [])

    const copy =
        type === "modal" ? "Comparte Free Markets Destroy:" : "Compartir artículo:"

    return (
        <div
            className={
                styles.share + " " + (type == "signup" ? styles.signup : "")
            }
        >
            <span>{copy}</span>

            <div className={styles.links}>
                <a
                    href={
                        "https://www.facebook.com/sharer/sharer.php?u=" +
                        encodeURIComponent(location)
                    }
                    target="_blank"
                    className={styles.fb}
                    onClick={share}
                ></a>
                <a
                    target="_blank"
                    href={location}
                    className={styles.link}
                    onClick={shareLink}
                ></a>
            </div>
        </div>
    )
}

export default Share
