import React, { useRef, useEffect, useState } from "react"

import "../../styles/select.scss"
import fetchJsonp from "../../utils/fetchJsonp"

let states

const Form = ({ type = "signup", styles, triggerSuccess }) => {
    const form = useRef()
    const [selectVal, setSelectVal] = useState("")
    const [focused, setFocused] = useState(false)
    const [hasContent, setHasContent] = useState(false)
    // const [success, setSuccess] = useState(false)
    const [invalid, setInvalid] = useState(false)
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)

    const setCookie = () => {
        const now = new Date()
        let time = now.getTime()
        time += 60 * 60 * 1000
        now.setTime(time)
        document.cookie = `popup=true; expires=${now.toUTCString()}; path=/`
    }

    const submitForm = e => {
        e.preventDefault()

        if (!loading) {
            let data = new FormData(e.currentTarget)
            let action = e.currentTarget.getAttribute("action")
            let params = new URLSearchParams([...data])
            setLoading(true)
            fetchJsonp(`${action}?${params.toString()}`, {
                jsonpCallback: "c",
            })
                .then(response => response.json())
                .then(
                    response => {
                        const { msg, result } = response

                        const success =
                            result === "success" ||
                            msg.includes("is already subscribed") ||
                            msg.includes("has too many recent signup requests")

                        const badEmail =
                            msg.includes(
                                "Please enter a different email address"
                            ) || msg.includes("email address is invalid")

                        if (success) {
                            triggerSuccess()
                            setCookie()
                            window.dataLayer.push({
                                event: "SubscribeFormSubmitted",
                            })
                        } else if (badEmail) setInvalid(true)
                        else setError(true)

                        setLoading(false)
                        // track({ event: "SubscribeFormSubmitted" })
                    },
                    error => {
                        setError(true)
                        setLoading(false)
                    }
                )
        }
    }

    const generateSelect = () => {
        let num = 0
        return (
            <select
                className={styles.select}
                autoComplete="off"
                name="STATE"
                value={selectVal}
                placeholder="Select State"
                onChange={handleChange}
                required
            >
                <option value="" disabled hidden>
                    Select State
                </option>
                {states.map(state => {
                    return (
                        <option
                            key={type + "-form-" + num++}
                            value={state.title}
                        >
                            {state.title}
                        </option>
                    )
                })}
            </select>
        )
    }

    function handleChange(event) {
        setSelectVal(event.target.value)
    }

    return (
        <form
            method="POST"
            action="https://washingtonpolicy.us15.list-manage.com/subscribe/post-json"
            className={styles.form}
            ref={form}
            onSubmit={submitForm}
        >
            <input type="hidden" name="u" value="908eb5762c61340c748d43bde" />
            <input type="hidden" name="id" value="e77f76b9a7" />

            <input
                name="EMAIL"
                className={styles.email}
                type="email"
                placeholder="Enter your email"
                required
            />

            <div className={styles.selectWrap}>{generateSelect()}</div>

            <button
                className={styles.submit}
                type="submit"
                // onClick={submit}
            >
                <span>Sign up now</span>
            </button>
        </form>
    )
}

states = [
    { value: "AL", title: "Alabama" },
    { value: "AK", title: "Alaska" },
    { value: "AZ", title: "Arizona" },
    { value: "AR", title: "Arkansas" },
    { value: "CA", title: "California" },
    { value: "CO", title: "Colorado" },
    { value: "CT", title: "Connecticut" },
    { value: "DE", title: "Delaware" },
    { value: "DC", title: "District Of Columbia" },
    { value: "FL", title: "Florida" },
    { value: "GA", title: "Georgia" },
    { value: "HI", title: "Hawaii" },
    { value: "ID", title: "Idaho" },
    { value: "IL", title: "Illinois" },
    { value: "IN", title: "Indiana" },
    { value: "IA", title: "Iowa" },
    { value: "KS", title: "Kansas" },
    { value: "KY", title: "Kentucky" },
    { value: "LA", title: "Louisiana" },
    { value: "ME", title: "Maine" },
    { value: "MD", title: "Maryland" },
    { value: "MA", title: "Massachusetts" },
    { value: "MI", title: "Michigan" },
    { value: "MN", title: "Minnesota" },
    { value: "MS", title: "Mississippi" },
    { value: "MO", title: "Missouri" },
    { value: "MT", title: "Montana" },
    { value: "NE", title: "Nebraska" },
    { value: "NV", title: "Nevada" },
    { value: "NH", title: "New Hampshire" },
    { value: "NJ", title: "New Jersey" },
    { value: "NM", title: "New Mexico" },
    { value: "NY", title: "New York" },
    { value: "NC", title: "North Carolina" },
    { value: "ND", title: "North Dakota" },
    { value: "OH", title: "Ohio" },
    { value: "OK", title: "Oklahoma" },
    { value: "OR", title: "Oregon" },
    { value: "PA", title: "Pennsylvania" },
    { value: "RI", title: "Rhode Island" },
    { value: "SC", title: "South Carolina" },
    { value: "SD", title: "South Dakota" },
    { value: "TN", title: "Tennessee" },
    { value: "TX", title: "Texas" },
    { value: "UT", title: "Utah" },
    { value: "VT", title: "Vermont" },
    { value: "VA", title: "Virginia" },
    { value: "WA", title: "Washington" },
    { value: "WV", title: "West Virginia" },
    { value: "WI", title: "Wisconsin" },
    { value: "WY", title: "Wyoming" },
]

export default Form
